$(function () {

    var menu = $('#header-container nav'),
        menu_toggle = menu.find('h1'),
        menu_list = menu.find('ul');

    enquire.register("screen and (max-width:1023px)", {

        match : function() {

            // Hide menu by default
            menu_list.hide();

            // Toggle menu
            menu_toggle.show().click(function() {
                menu_list.slideToggle(250);
            });

        },                              
        unmatch : function() {

            // Show menu
            menu_list.show();

            // Hide menu toggle
            menu_toggle.unbind('click').hide();

        }
                  
    });

});